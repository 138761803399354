/*
 * 업무구분: Gateway
 * 화 면 명: MSPFS002M
 * 화면설명: Gateway
 * 작 성 일: 2023.03.21
 * 작 성 자: 송진의
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="Gateway" :topButton="false">
    <header class="fts-header">
      <ur-box-container alignV="center" direction="row" class="fts-flex-sb">
        <nav class="btn-content">
          <mo-icon-button class="fts-gateway" :borderless="true">홈으로</mo-icon-button>
        </nav>
      </ur-box-container>
    </header>

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="row">
        <div class="wrap-fts-worktype">
          <mo-button class="btn-fs" @click="fn_GoPage('fs')">
            <div class="wrap-bg"></div>
            <span>수익증권</span>
          </mo-button>
          <mo-button class="btn-ts" @click="fn_GoPage('ts')">
            <div class="wrap-bg"></div>
            <span>신탁</span>
          </mo-button>
          <div style="display:inline-block;width:100px;" v-if="lv_serverType === 'local' || lv_serverType === 'development'">
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="startRecode()">녹취시작</button><br />
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="endRecode()">녹취종료</button><br />
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="encriptRcno()">개인정보암호화</button>
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="encriptPhone()">폰번호암호화</button>
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="transRecodeFile()">NAS전송</button>            
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="fn_GoTestPage">TESTPAGE TS</button>            
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="fn_GoTestPage02">TESTPAGE FS</button>
            <button style="width:100%;height:1rem;border-radius:0.375rem;margin-bottom:3px;" @click="fn_GoTestPage03">커버발행</button>
          </div>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <fs-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>


  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import axios2 from 'axios';
import FSFileUtil from "@/ui/fs/comm/FSFileUtil"
import FSInfoUtil from "@/ui/fs/comm/FSInfoUtil"

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS002M",
  screenId: "MSPFS002M",
  components: {
    'fs-alert-popup': FSAlertPopup,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.lv_serverType = process.env.NODE_ENV
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS002M")    
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isShowAlert: false,
      isShowLoading: true,
      isShowLoading1: false,
      isShowLoading2: false,
      doubleUploadFlag_NAS: false,

      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),

      pAlertPopupObj: {},

      lv_basInfo_fs: this.getStore('fsStore').getters.getBasInfo.data,
      lv_basInfo_ts: this.getStore('tsStore').getters.getBasInfo.data,
      lv_epId: '', 

      busnScCd: '', //업무구분코드
      recdYmd: '', //녹취일자
      recdStrTm: '', //녹취시작시간
      recdGudeEno: '', //녹취안내자사번
      recdTotPhclTm: '', //녹취총통화시간
      recdFileNm: '', //녹취파일명
      custId: '', //고객ID
      custNm: '', //고객명
      custRrnEncr: '', //고객주민등록번호암호화
      custTelnoEncr: '', //고객전화번호암호화
      nrmTrtYn: 'Y', //정상처리여부
      lstTrtYn: 'N', //최종처리여부
      recdTrtDtm: '', //녹취처리일시
      currDt: '', //녹취시작일시
      endDt: '', //녹취종료일시

      selectedFile: null,
      lv_serverType: ''
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal1() {return this.$refs.visitAgree},
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_AlertPopup
    * 설명       : alert popup 호출(공통)
    ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },
    fn_getKsignKey () {
      let auth = 'S'
	    let lv_vm = this
	    let pParams = {}
      window.vue.post(lv_vm, pParams, 'txFSRCD10S1', '')
        .then((response) => {
          try {
            // debugger
		        console.log("response....................."+JSON.stringify(response));
            let encrKey = response.body.encrKey;
            let encrUUID = response.body.encrUUID;
            let errCode = response.body.errCode;
            let errMsg = response.body.errMsg;
		        console.log("encrKey....................."+encrKey);
		        console.log("encrUUID....................."+encrUUID);
		        console.log("errCode....................."+errCode);
		        console.log("errMsg....................."+errMsg);
          } catch (e) {
            window.vue.error(e)  
          }
        })
        .catch((error) => {
          window.vue.error(error)
        })

      },

    startRecode() {
      //alert('녹취 시작')

      let auth = 'S'
	    let lv_vm = this
	    let pParams = {}
      window.vue.post(lv_vm, pParams, 'txFSRCD10S1', '')
        .then((response) => {
          try {
		        console.log("response....................."+JSON.stringify(response));
            let encrKey = response.body.encrKey;
            let encrUUID = response.body.encrUUID;
            console.log("key/uuid..............." + encrKey + ".../..." + encrUUID);
            if (encrKey == null || encrKey == ""){              
              lv_vm.$addSnackbar('암호키 생성 실패.')
              return;
            }
            lv_vm.startRecodeRslt(encrKey,encrUUID);
          } catch (e) {
            window.vue.error(e)  
          }
        })
        .catch((error) => {
          window.vue.error(error)
        })

    },
    fn_strToDate(sDate){
      let nYear = parseInt(sDate.substr(0, 4));
      let nMonth = parseInt(sDate.substr(4, 2)) - 1;
      let nDate = parseInt(sDate.substr(6, 2));
      
      let nHour = parseInt(sDate.substr(8, 2));
      let nMin = parseInt(sDate.substr(10, 2));
      let nSec = parseInt(sDate.substr(12, 2));
      
      return new Date(nYear, nMonth, nDate, nHour, nMin, nSec);
    },
    startRecodeRslt(key, uuid) {

      this.currDt = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
      this.recdYmd = this.currDt.substr(0,8)
      this.recdStrTm = this.currDt.substr(8,6)
      console.log("this.currDt............"+this.currDt);

      let userEno = localStorage.getItem('userEno')
      if (userEno == null || userEno == "") userEno = "99999" // 사원번호 없는 경우
      this.recdGudeEno = userEno
      this.recdFileNm = this.currDt + '_' + userEno     // 날짜시분초 + 고객ID(사용자ID)
      console.log("this.recdFileNm..................." + this.recdFileNm);
      this.busnScCd = "05"
      if ( this.$options.components != null && this.$options.components["ts-header"] != null){
        this.busnScCd = "06"
      }

      window.fdpbridge.exec('RecordStartPlugin', {fileNm:this.recdFileNm, key, uuid}, () => {alert("OK")}, () => {alert("ERROR")})
    },
    
    endRecode() {
      const cust = {
        bfcer_cust_no: '123456',
        custNm: '홍길순',
        rrnAesEncr: '800101',
        celph: '01056785678',
      }      

      const rec = {
        recdId: this.$route.name + '',
        recdFileNm: this.recdFileNm,
        recdGudeEno: this.recdGudeEno,
        busnScCd: '05',
        recdStrTm: this.recdStrTm,
        recdYmd: this.recdYmd,
        currDt: this.currDt
      }

      FSFileUtil.gfn_setJobTyp(this, cust, rec)
      FSFileUtil.gfn_endRecord(this)
    },
    
    transRecodeFile() {
      // 녹취파일 업로드 중복처리 방지
      if ( this.doubleUploadFlag_NAS ) {
        this.$addSnackbar("녹취 전송 중 입니다.");
        // PSServiceManager.setDynaTraceLog('fn_UploadRecFilePlugin', '녹취파일 업로드 중복:' + this.recdFileNm + ':NAS:' + this.doubleUploadFlag_NAS + ':DC:' + this.doubleUploadFlag_DC, 'MSPPS610M', this.itemData.baseData.eltrnDoctId) //180M
        return
      }
      let lv_vm = this
      let jobPath = "REC_FILE_DIR_FS";
      if ( this.$options.components != null && this.$options.components["ts-header"] != null){
        jobPath = "REC_FILE_DIR_TS";
      }
      let t_Param = {
        serverurl:jobPath,
        fileNm: this.recdFileNm, // 저장파일명
      }
      this.fn_trace('info', '녹취파일 업로드 시작합니다.', t_Param)
      this.fn_ShowLoading(true, '2') // 화면 로딩 처리 // 화면 로딩
      this.doubleUploadFlag_NAS = true
      let tmp = this.recdFileNm + '.mp3.crypt' // filename 다이나로그남기기 20230327 
        window.fdpbridge.exec('RecordUploadPlugin', t_Param, function (result) {
          console.log('RecordUploadPlugin::result::::', JSON.stringify(result))
          lv_vm.doubleUploadFlag_NAS = false
          if (result.data === '200' || result.data === 'ok') {
            lv_vm.fn_insRecdFile () //녹취파일 저장
          } else {
            lv_vm.fn_insRecdFile (1) //녹취파일 오류
            lv_vm.fn_AlertMsg('upload', '파일 처리 중 에러가 발생했습니다.\n다시 시도하시겠습니까?', '아니오|예')
          } // end else if
        }, function (result) {
          lv_vm.fn_insRecdFile (2) //녹취파일 오류
          lv_vm.fn_trace('error', '녹취파일 업로드 실패 코드3', result)          
        })
    },

    fn_insRecdFile (err) {
      if (err == undefined || err == null) err = 0;
      let nrmTrtYn = "Y";
      if (err != 0) nrmTrtYn = "N";
      this.recdTrtDtm = PSDateUtil.fn_CurrentDate('yyyyMMddHHmmss')
      let auth = 'I'
	    let lv_vm = this
      let pParams = {
        busnScCd: this.busnScCd ,
        recdYmd: this.recdYmd ,
        recdStrTm: this.recdStrTm ,
        recdGudeEno: this.recdGudeEno ,
        recdTotPhclTm: this.recdTotPhclTm ,
        recdFileNm: this.recdFileNm + '.mp3.crypt' ,
        custId: this.recdGudeEno ,  ////////////// 차후 변경 this.custId
        custNm: "test" ,   ////////////////// 차후 변경 this.custNm
        custRrnEncr: "8001011488611" ,   ////////////////// 차후 변경 this.custRrnEncr
        custTelnoEncr: "01012341234" ,   ////////////////// 차후 변경 this.custTelnoEncr
        nrmTrtYn: nrmTrtYn ,
        lstTrtYn: this.lstTrtYn ,
        recdTrtDtm: this.recdTrtDtm
      }
      console.log("fn_insRecdFile........................" + JSON.stringify(pParams));
      window.vue.post(lv_vm, pParams, 'txTSSBC99I1', '')
        .then((response) => {
          try {
		        console.log("insert response....................."+JSON.stringify(response));
          } catch (e) {
            window.vue.error(e)  
          }
        })
        .catch((error) => {
          window.vue.error(error)
        })
    },    
    encriptPhone() {
      this.encriptInfo(2,"01012341234");   //phone
    },
    encriptInfo(mode,data){
      //mode:1.rcno, 2.phone      
      let key = "f17af8ac306c2297a42877fe93df32c77ee7480c0cbe4b7cff5704ab197800cb";
      window.fdpbridge.exec('RecordAES256Plugin', {key:key, data:data},
        (result) => {
          //ok
          if (mode==1){
            this.custRrnEncr = result;
          }else if(mode==2){
            this.custTelnoEncr = result;
          }
        },
        () => {
          //error
          if (mode==1){
            this.custRrnEncr = "error";
          }else if(mode==2){
            this.custTelnoEncr = "error";
          }
        })
    },

    /************************************************************************************************
     * Function명  : fn_trace
     * 설명        : 프로그램 흐름 및 에러 로그 추적 공통 함수
     ************************************************************************************************/
    fn_trace: function (type, addMsg, outData, msgType = ' ', fromScreenId) {
      alert(addMsg);
    },

    /************************************************************************************************
     * Function명  : fn_ShowLoading
     * 설명        : 화면 로딩 처리 공통 메소드
     ************************************************************************************************/
    fn_ShowLoading: function (isShow, showType = ' ') {
      console.log('this.fn_ShowLoading Call', 'showType : ' + showType)
      this.isShowLoading = isShow // 화면 로딩
      if (showType === '0') {
        this.isShowLoading1 = false // 진행 중
        this.isShowLoading2 = false // 처리 중
      } else if (showType === '1') {
        this.isShowLoading1 = true // 진행 중
        this.isShowLoading2 = false // 처리 중
      } else if (showType === '2') {
        this.isShowLoading1 = false // 진행 중
        this.isShowLoading2 = true // 처리 중
      } // end else if
    },

    // modal
    openModal(type) {
      switch (type) {
        case 1:
          this.modal1.open();
          break;
      }
    },
    closeModal(type) {
      switch (type) {
        case 1:
          this.modal1.close();
          break;
      }
    },
    /******************************************************************************
     * Function명 : fn_GoPage
     * 설명       : 페이지 이동
     ******************************************************************************/
    fn_GoPage(type) {
      let page = ''      
      if(type === 'fs') {        
        page = 'MSPFS001M'        
      } else {
        page = 'MSPTS001M'
      }

      this.$router.push({
        name: page, params: {jobTyp: type}
      })
      
    },
    /******************************************************************************
     * Function명 : fn_GoTestPage
     * 설명       : 테스트 페이지 이동
     ******************************************************************************/
    fn_GoTestPage() {
      this.$router.push({
        name: 'MSPTSTEST'
      })
    },
    fn_GoTestPage02() {
      this.$router.push({
        name: 'MSPFSTEST'
      })
    },
    fn_GoTestPage03() {
      this.$router.push({
        name: 'MSPFS900M'
      })
    },

    onFileChange(event){
      this.selectedFile = event.target.files[0];
    },
    uploadFile(){
      let formData = new FormData();
      formData.append('path', 'D:/temp/');
      formData.append('file', this.selectedFile);
      axios2.post('http://localhost:1111/uploadrec.do', formData)
      .then(response => {
        console.log("response..........." + response.data);
      })
      .catch(error => {
        console.log("error..........." + error);
      });
    },
  }
}
</script>
<style scoped>
</style>